import React from 'react';
import PropTypes from 'prop-types';
import {
  ProductCard,
  ProductCardCRO,
  CollapsibleMenu,
  RecommendedProductCard,
} from '@web/molecules';
import { toggleItem } from '../../toggle';
import {
  PRICE_PARITY_STARTING_AT_TEXT_KEY,
  SHOW_LAMB,
  useExperiment,
} from '../../experiments';
import {
  ProductSelectionContainer,
  ProductCardWrapper,
  MenuWrapper,
} from './styles.js';

export const ProductSelector = ({
  products,
  collapsedProducts = [],
  unavailableProducts = [],
  selected = [],
  onChange,
  onDetailsClick,
  variant = 'checkbox',
  period = 'week',
  productCardVariant = 'classic',
  direction = 'horizontal',
  notSelectableText,
  buttonIcon,
  gapSize = 4,
  gridColumns = 3,
  cardOrientation = 'row',
  stackOnMobile = true,
}) => {
  const handleChange = (selected, product) => {
    variant === 'checkbox'
      ? onChange?.(toggleItem(selected, product))
      : !selected.includes(product) && onChange?.([product]);
  };

  const { value: showPriceText } = useExperiment(
    PRICE_PARITY_STARTING_AT_TEXT_KEY,
    false,
  );

  const { value: showLambUnavailableText } = useExperiment(SHOW_LAMB, true);

  const lambShortageText =
    "Our Lamb Dish with Cranberries is temporarily out of stock due to an inventory shortage. We're working like dogs to get this recipe back on the menu and appreciate your patience and understanding.";

  const getProductCard = (cardVariant, props) => {
    if (cardVariant === 'classic') {
      return <ProductCard {...props} />;
    } else if (cardVariant === 'cro') {
      return <ProductCardCRO {...props} />;
    } else {
      return (
        <RecommendedProductCard
          {...props}
          badge={props.benefit || props.badge}
        />
      );
    }
  };

  return (
    <ProductSelectionContainer
      $gridColumns={products.length === 4 ? 2 : gridColumns}
      $gapSize={gapSize}
      $direction={direction}
      $stackOnMobile={stackOnMobile}
    >
      {products.map(product => {
        const isUnavailable = unavailableProducts.includes(product.value);
        const notSelectable =
          selected.length === 4 && !selected.includes(product.value);

        const cardProps = {
          ...product,
          unavailable: isUnavailable,
          unavailableText:
            product.value !== 'lamb'
              ? product.unavailableText
              : showLambUnavailableText
              ? product.unavailableText
              : lambShortageText,
          notSelectable,
          notSelectableText,
          onDetailsClick: () => onDetailsClick(product),
          onChange: e => handleChange(selected, product.value),
          checked: selected.includes(product.value),
          inputType: variant,
          callout: product.callout,
          period,
          showPriceText,
          buttonIcon,
          orientation: cardOrientation,
        };
        return (
          <ProductCardWrapper key={product.value}>
            {getProductCard(productCardVariant, cardProps)}
          </ProductCardWrapper>
        );
      })}
      {collapsedProducts.length > 0 && (
        <MenuWrapper>
          <CollapsibleMenu menuText="See more plans">
            {collapsedProducts.map(product => {
              const isUnavailable = unavailableProducts.includes(product.value);
              const notSelectable =
                selected.length === 4 && !selected.includes(product.value);
              const cardProps = {
                ...product,
                unavailable: isUnavailable,
                unavailableText:
                  product.value !== 'lamb'
                    ? product.unavailableText
                    : showLambUnavailableText
                    ? product.unavailableText
                    : lambShortageText,
                notSelectable,
                notSelectableText,
                onDetailsClick: () => onDetailsClick(product),
                onChange: e => handleChange(selected, product.value),
                checked: selected.includes(product.value),
                inputType: variant,
                callout: product.callout,
                period,
                showPriceText,
                buttonIcon,
                orientation: cardOrientation,
              };
              return (
                <ProductCardWrapper key={product.value}>
                  {getProductCard(productCardVariant, cardProps)}
                </ProductCardWrapper>
              );
            })}
          </CollapsibleMenu>
        </MenuWrapper>
      )}
    </ProductSelectionContainer>
  );
};

ProductSelector.displayName = 'ProductSelector';
ProductSelector.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  collapsedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  unavailableProducts: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onDetailsClick: PropTypes.func,
  variant: PropTypes.oneOf(['checkbox', 'radio']),
  productCardVariant: PropTypes.oneOf(['classic', 'cro', 'recommended']),
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  cardOrientation: PropTypes.oneOf(['row', 'column']),
  notSelectableText: PropTypes.string.isRequired,
  period: PropTypes.string,
  buttonIcon: PropTypes.string,
  gapSize: PropTypes.number,
  stackOnMobile: PropTypes.bool,
};
