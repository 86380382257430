'use client';
import styled, { css } from 'styled-components';

export const ProductSelectionContainer = styled.div`
  min-width: 0;
  width: fit-content;
  max-width: 100vw;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(props.$gapSize)};
  margin: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    !props.$stackOnMobile &&
    css`
      display: grid;
      grid-template-columns: repeat(${props => props.$gridColumns}, 1fr);
    `};

  @media ${props => props.theme.laptop} {
    ${props =>
      props.$direction === 'horizontal' &&
      css`
        display: grid;
        grid-template-columns: repeat(${props => props.$gridColumns}, 1fr);
      `};
    padding: 0;

    margin: ${props => props.theme.spacing(0, 'auto', 10, 'auto')};
  }
`;

export const ProductCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: min-content;
  max-width: 33%;
`;

export const MenuWrapper = styled.div`
  min-width: 345px;
  color: ${props => props.theme.colors.text.primary};
`;

export const CollapsedMenuSubheading = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.tertiary};
  margin-left: ${props => props.theme.spacing(6)};
`;
